import Password from "./form-elements/password-input";
import Boolean from "./form-elements/boolean-input";
import Helper from "../polyfills/helper";

const Form = (function(){
  function init() {
    Helper.handleCustomEvent('ajax::refresh', init)
    Password.init()
    Boolean.init();
    hookUpSubmitButtons();
  }

  function handleErrors(formElement, errors) {
    clearErrors(formElement);

    if (!errors) { return; }

    for (const [key, value] of Object.entries(errors)) {

      let fieldErrorElement = formElement.querySelector('[data-attribute-name=' + key + ']')
      if (!fieldErrorElement) {
        const sanitizedKey = key.slice(0, -1) + '_ids';
        fieldErrorElement = formElement.querySelector('[data-attribute-name=' + sanitizedKey + ']')
      }
      if (fieldErrorElement) {
        const errorElement = document.createElement('div')
        errorElement.classList.add('error-notification')
        errorElement.classList.add('help')
        errorElement.classList.add('is-danger')
        errorElement.innerText = value
        fieldErrorElement.appendChild(errorElement)
        fieldErrorElement.classList.add('field_with_errors')
      }
    }
  }

  function clearErrors(formElement) {
    let fieldErrorElements = formElement.querySelectorAll('[data-attribute-name]')

    Array.from(fieldErrorElements).forEach(fieldElement => {
      fieldElement.classList.remove('field_with_errors')
    });

    const errorElements = formElement.querySelectorAll('.error-notification')
    errorElements.forEach(errorElement => {
      errorElement.remove();
    })
  }

  function hookUpSubmitButtons() {
    const submitElements = document.querySelectorAll('[data-submit]');
    Array.from(submitElements).forEach(submitElement => {
      console.log(submitElement.type)
      if (submitElement.type == "select-one"){
        submitElement.removeEventListener('change', onSubmit)
        submitElement.addEventListener('change', onSubmit)
      } else {
        submitElement.removeEventListener('click', onSubmit)
        submitElement.addEventListener('click', onSubmit)
      }
    })
  }

  function onSubmit(event) {
    var formTarget = event.currentTarget.dataset.form;
    if (!formTarget) {
      formTarget = event.currentTarget.form;
    }
    formTarget.submit()
  }

  function getValueFromInput(input){
    if (input.type == 'radio' || input.type == 'checkbox'){
      return input.checked ? true : false;
    }
    return input.value;
  }

  return {
    init: init,
    handleErrors: handleErrors,
    clearErrors: clearErrors,
    getValueFromInput: getValueFromInput,
  }
})();

export default Form;
