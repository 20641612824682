import Api from "../ajax/api";
import Helper from "../polyfills/helper";

const Ubo = (function(){

  function init(){
    Helper.handleCustomEvent('ajax::refresh', init);

    var ubosContainerElement = document.querySelector('[data-ubos]');
    if (!ubosContainerElement) { return; }

    var subElements = ubosContainerElement.querySelectorAll('[data-ubo]');
    subElements.forEach(function(element){
      element.style.display = 'none'
    })

    var firstElement = ubosContainerElement.querySelector("[data-ubo='1']")
    firstElement.style.display = 'block'

    var startIndex = ubosContainerElement.dataset.uboIndex
    if (!startIndex){
      startIndex = 0
    } else {
      startIndex = parseInt(startIndex)
    }

    ubosContainerElement.dataset.uboIndex = startIndex;

    var addElement = ubosContainerElement.querySelector("[data-add-ubo]")
    addElement.removeEventListener('click', addUboElement)
    addElement.addEventListener('click', addUboElement)

    handleSubElements(ubosContainerElement);
  }

  function addUboElement(event){
    var target = event.currentTarget;
    var ubosContainerElement = target.closest('[data-ubos]');
    handleSubElements(ubosContainerElement);
  }

  function handleSubElements(ubosContainerElement){
    var uboIndex = parseInt(ubosContainerElement.dataset.uboIndex);
    uboIndex += 1;
    ubosContainerElement.dataset.uboIndex = uboIndex;

    var counter = 1;
    var subElements = ubosContainerElement.querySelectorAll('[data-ubo]');
    subElements.forEach(function(element){
      if (counter <= uboIndex){
        element.style.display = 'block'
      } else {
        element.style.display = 'none'
      }
      counter += 1;
    })
    if (uboIndex == 5){
      var addElement = ubosContainerElement.querySelector("[data-add-ubo]")
      addElement.style.opacity = '0.2';
      addElement.style.pointerEvents = 'none'
    }
  }

  return {
    init: init
  }
})();

export default Ubo;
